<template>
  <svg
    enable-background="new 0 0 459 459"
    version="1.1"
    viewBox="0 0 459 459"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    class="inline fill-current"
    :class="[width, height, color]"
  >
    <path
      d="M408,0H51C22.95,0,0,22.95,0,51v357c0,28.05,22.95,51,51,51h357c28.05,0,51-22.95,51-51V51C459,22.95,436.05,0,408,0z     M178.5,357L51,229.5l35.7-35.7l91.8,91.8L372.3,91.8l35.7,35.7L178.5,357z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: 'w-4'
    },
    height: {
      type: String,
      default: 'h-4'
    },
    color: {
      type: String,
      default: 'text-primary'
    }
  }
}
</script>
