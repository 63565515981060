<template>
  <div>
    <div class="">
      <div @click="show_pet = !show_pet">
        <input-text
          type="text"
          placeholder="Pet Type"
          readonly
          v-model="pet_selected"
        ></input-text>
        <div class="relative">
          <a href="javascript:;">
            <selectarrow-icon
              class="absolute right-0 -mt-8 mr-2"
              :width="'w-4'"
              :height="'h-4'"
              :color="'text-primary'"
            ></selectarrow-icon>
          </a>
        </div>
      </div>
      <transition name="localform">
        <div class="relative" v-if="show_pet">
          <div
            class="
              bg-white
              absolute
              z-30
              rounded
              mt-1
              w-full
              border-t
              shadow-md
            "
          >
            <div class="overflow-y-scroll max-h-44">
              <p
                class="px-3 py-2 hover:bg-gray-300"
                :class="{
                  'rounded-t': index == 0,
                  'rounded-b': index + 1 == pets.length
                }"
                @click="selectPets(index)"
                v-for="(pet, index) in pets"
                :key="index"
              >
                {{ pet.name }}
              </p>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import SelectarrowIcon from '../Icons/selectarrowIcon.vue'
import InputText from './InputText.vue'
export default {
  components: { InputText, SelectarrowIcon },
  props: {
    pet_type_db: String
  },
  data() {
    return {
      pets: [
        { name: 'Dog', id: 1 },
        { name: 'Cat', id: 2 },
        { name: 'Cattle', id: 3 },
        { name: 'Pig', id: 4 },
        { name: 'Guinea pig', id: 5 },
        { name: 'Rats', id: 6 },
        { name: 'Fish', id: 7 },
        { name: 'Rabbit', id: 8 },
        { name: 'Bird', id: 9 },
        { name: 'Amphibians', id: 10 },
        { name: 'Ferret', id: 11 },
        { name: 'Mice', id: 12 },
        { name: 'Horse', id: 13 }
      ],
      show_pet: false,
      pet_selected: ''
    }
  },
  watch: {
    pet_selected(val) {
      this.$emit('add-pet-type', val)
    }
  },
  methods: {
    selectPets(val) {
      this.pet_selected = this.pets[val].name
      this.show_pet = false
    }
  },
  mounted() {
    if (this.pet_type_db != '') {
      this.pet_selected = this.pet_type_db
    }
  }
}
</script>

<style>
.localform-enter-active,
.localform-leave-active {
  transition: transition ease-in, opacity ease-in-out;
  @apply duration-300;
}

.localform-enter,
.localform-leave-to {
  opacity: 0;
}

.localform-leave,
.localform-enter-to {
  opacity: 1;
}
</style>
