<template>
  <div class="inline-block absolute cursor-pointer">
    <span @click="controlChecked">
      <uncheckbox
        v-if="input_unchecked"
        :width="'w-8'"
        :height="'h-8'"
        :color="'text-gray-400'"
      ></uncheckbox>
    </span>
    <span @click="controlChecked">
      <check-box
        v-if="input_checked"
        :width="'w-7'"
        :height="'h-7'"
        :color="'text-primary'"
      >
      </check-box>
    </span>
  </div>
</template>

<script>
import CheckBox from '../Icons/checkBox.vue'
import uncheckbox from '../Icons/uncheckbox.vue'
export default {
  components: { uncheckbox, CheckBox },
  props: {
    checked: Boolean,
    unchecked: Boolean
  },
  data() {
    return {
      input_checked: false,
      input_unchecked: true
    }
  },
  watch: {},
  methods: {
    controlChecked() {
      this.input_checked = !this.input_checked
      this.input_unchecked = !this.input_unchecked
      var value = {
        checked: this.input_checked
      }
      this.$emit('checked-value', value)
    }
  },
  mounted() {
    // if (this.checked_from_db != undefined && this.unchecked_from_db) {
    //   this.input_checked = this.checked_from_db
    //   this.input_unchecked = this.unchecked_from_db
    // }
  }
}
</script>

<style></style>
